/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 43번째 로그에서는 갤럭시 노트 10, 테슬라 모델 3, Go 언어 입문, 테라폼 삽질 공유회 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "갤럭시 노트 10(Galaxy Note 10) 예약"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.samsung.com/us/mobile/galaxy-note10/"
  }, "Galaxy Note10 & Note10+ | Features & Specifications | Samsung US")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/"
  }, "Bear - Notes for iPhone, iPad and Mac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/kb/SP738?locale=en_US&viewlocale=ko_KR"
  }, "iPhone SE - 제품사양")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.samsung.com/sec/galaxy-note10/preorder/"
  }, "Galaxy Note10 | 10+ 5G 사전판매 | 삼성닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.samsung.com/global/galaxy/galaxy-s9/"
  }, "Samsung Galaxy S9 and S9+ – The Official Samsung Galaxy Site")), "\n"), "\n", React.createElement(_components.h2, null, "테슬라 모델 3(Tesla Model 3) 한국 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=C-yFJmWvhz0"
  }, "테슬라 모델 3 구매를 고민하는 히치하이커를 위한 안내서(feat 코나 EV 오너) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hyundai.com/kr/ko/vehicles/kona-electric/specifications"
  }, "코나 Electric | 현대자동차")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tesla.com/ko_KR/model3"
  }, "Model 3 | Tesla")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tesla.com/ko_KR/findus/location/store/starfieldhanam"
  }, "스타필드 하남 | Tesla")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=yWekgt8Dc9o&t=29s"
  }, "오픈핵 행사 마치고 돌아가는 차에서 잡담 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tesla.com/ko_KR/autopilot"
  }, "오토파일럿 | Tesla")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.motorgraph.com/news/articleView.html?idxno=23270"
  }, "테슬라 상하이 기가팩토리 3, 올 하반기 본격 가동 - 모터그래프")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tesla.com/ko_KR/findus/list/superchargers/South%20Korea"
  }, "한국의 Tesla 수퍼차저 | Tesla")), "\n"), "\n", React.createElement(_components.h2, null, "정부24, 엑티브X 없이 사용 가능"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.donga.com/news/article/all/20190813/96949198/1"
  }, "정부24 등 공공 웹사이트, 액티브엑스 안 깔아도 된다")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/workspaces/"
  }, "데스크탑 클라우드 가상화 솔루션 | Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Remote_Desktop_Protocol"
  }, "Remote Desktop Protocol - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%95%84%EC%9D%B4%ED%95%80"
  }, "아이핀 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.uber.com/kr/ko/"
  }, "Uber - 지금 차량 서비스를 이용하거나 차량 운행으로 수익을 올리세요")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.grab.com/sg/"
  }, "Grab – Transport, Food Delivery & Payment Solutions")), "\n"), "\n", React.createElement(_components.h2, null, "Go 프로그래밍 언어 입문"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://golang.org/cmd/gofmt/"
  }, "gofmt - The Go Programming Language")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "44bits.io/"
  }, "Go(고) 언어 설치하기 feat. VS Code - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.keyboardmaestro.com/main/"
  }, "Keyboard Maestro 9.0: Work Faster with Macros for macOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/seapy/decolink"
  }, "seapy/decolink: Decoration link")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/fallroot/copy-url-for-alfred"
  }, "fallroot/copy-url-for-alfred: You can copy browser's URL and title with various formats like markdown, anchor tag and your own.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://circleci.com/gh/seapy/decolink"
  }, "seapy/decolink | CircleCI")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.github.com/actions/"
  }, "GitHub Actions | GitHub Developer Guide")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/mitchellh/gox"
  }, "mitchellh/gox: A dead simple, no frills Go cross compile tool")), "\n"), "\n", React.createElement(_components.h2, null, "테라폼 삽질 공유회 후기 / ConfConf 2016"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/394"
  }, "테라폼 삽질 공유회 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/36/"
  }, "stdout_036.log: 오픈핵 2019, Little Big Data, Facebook Dev C 서울, Oculus Quest w/ jayjinjay | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.pycon.kr/program/open-space-talk"
  }, "Open Space - PyCon Korea 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://confconf.github.io/"
  }, "ConfConf 2016")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/playlist?list=PLLpp1MOTRVCAhT6yzo2mm0DKYFO0c_ri6"
  }, "ConfConf 2016 - YouTube")), "\n"), "\n", React.createElement(_components.h2, null, "구글 검색, 팟캐스트 검색 기능 추가"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.blog.google/products/search/press-play-find-and-listen-podcast-episodes-search/"
  }, "Press play: Find and listen to podcast episodes on Search")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bloter.net/archives/348084"
  }, "아프리카TV, NHN 팟캐스트 '팟티' 인수 | Bloter.net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.podfreeca.com/"
  }, "팟프리카")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://finance.naver.com/item/main.nhn?code=067160"
  }, "아프리카TV : 네이버 금융")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
